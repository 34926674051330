<template>
	<div class="container">
		<ByPickerBar :items="orderTypes" v-model="currentType"></ByPickerBar>
		<div class="type-content">
			<div class="type-title">
				<img :src="currentTab.img" class="type-img" />
				<p>{{ currentTab.name }}</p>
			</div>
			<template v-if="list.length">
				<div
					class="list"
					:class="{ active: item.show }"
					v-for="(item, index) in list"
					:key="item.id"
				>
					<div class="type-detail">
						<div class="text">
							<p>{{ $vtime.format(item.createTime, 'yyyy-MM-dd') }}</p>
							<p>|</p>
							<p>取餐號碼 {{ item.takeNumber }}</p>
						</div>
						<ByButton
							v-if="currentType <= 2"
							stl="main1"
							@click="takeMealDetail(item)"
							>取餐詳情
						</ByButton>
						<div class="right">
							<div class="total" v-if="!item.show">
								${{ $stepPrice(item.amountReceived) }}
							</div>
							<div class="expend-icon" @click="item.show = !item.show">
								<img
									src="../../../assets/back.svg"
									:class="item.show ? 'active' : ''"
								/>
							</div>
						</div>
					</div>
					<BySlider v-show="item.show">
						<div>
							<div class="center-page-minus">
								<div v-if="item.orderDetailList.length > 0">
									<div
										class="order-grid"
										v-for="(
											orderDetail, orderDetailIndex
										) in item.orderDetailList"
										:key="orderDetail.itemName + orderDetailIndex"
									>
										<div class="order-items">
											<span class="order-name">{{ orderDetail.itemName }}</span>
											<span class="order-amount"
												>數量 {{ orderDetail.count }}</span
											>
											<span class="order-price"
												>${{
													orderDetail.entertain
														? 0
														: $stepPrice($countOrderPrice(orderDetail))
												}}
											</span>
											<OptionDetailList :data="orderDetail" />

											<div
												class="order-combo"
												v-if="orderDetail.upgradeSeriesId"
											>
												<div class="order-combo__title">
													升級： {{ orderDetail.upgradeSeriesName
													}}{{
														orderDetail.upgradeSeriesPrice
															? `($${orderDetail.upgradeSeriesPrice})`
															: ''
													}}
												</div>
												<div
													class="order-combo__li"
													v-for="(
														upgrade, upgradeIndex
													) in orderDetail.upgradeList"
													:key="upgradeIndex"
													style="margin-bottom: 5px"
												>
													<div style="display: flex">
														<div>{{ upgrade.contentName }}</div>
														<div style="margin-left: 5px">
															- {{ upgrade.itemName }} x{{ upgrade.count }}
														</div>
														<div style="margin-left: 5px">
															{{ upgrade.price ? `($${upgrade.price})` : '' }}
														</div>
													</div>
													<OptionDetailList
														class="order-combo__item__options"
														:data="upgrade"
													/>
												</div>
											</div>

											<div
												class="order-combo"
												v-if="orderDetail.comboList.length > 0"
											>
												<div
													class="order-combo__li"
													v-for="(combo, comboIndex) in orderDetail.comboList"
													:key="comboIndex"
													style="margin-bottom: 5px"
												>
													<div style="display: flex">
														<div>{{ combo.contentName }}</div>
														<div style="margin-left: 5px">
															- {{ combo.itemName }} x{{ combo.count }}
														</div>
														<div style="margin-left: 5px">
															{{
																combo.price
																	? `($${combo.price * combo.count})`
																	: ''
															}}
														</div>
													</div>
													<OptionDetailList
														class="order-combo__item__options"
														:data="combo"
													/>
												</div>
											</div>

											<span
												class="order-li-entertain"
												v-if="orderDetail.entertain"
											>
												<span>-</span> 招待
											</span>
										</div>
									</div>
								</div>
								<div class="no-any" v-else>無任何餐點明細</div>
							</div>
							<div class="order-entertain" v-if="item.totalDiscountPrice > 0">
								<span>-</span>折扣{{ $stepPrice(item.totalDiscountPrice) }}元
							</div>
							<div class="order-entertain" v-if="item.entertain">
								<span>-</span> 全部招待
							</div>
							<div class="order-count" v-if="item.orderDetailList.length > 0">
								<span>總計</span>
								<span
									>${{
										item.entertain ? 0 : $stepPrice(item.amountReceived)
									}}</span
								>
							</div>
						</div>
					</BySlider>
				</div>
			</template>
			<div class="no-order" v-else>尚無訂單</div>
		</div>
		<ByFooter />
		<ByTopBtn></ByTopBtn>
	</div>
</template>

<script>
import OptionDetailList from '@/views/user/MyOrder/OptionDetailList'
export default {
	name: 'MyOrder',
	components: { OptionDetailList },
	data() {
		return {
			currentType: 1,
			list: [],
			orderTypes: [
				{
					id: 1,
					name: '待取餐',
					img: require('../../../assets/finish-clock.svg'),
				},
				{
					id: 2,
					name: '製作中',
					img: require('../../../assets/clock.svg'),
				},
				{
					id: 3,
					name: '已完成',
					img: require('../../../assets/finish-clock.svg'),
				},
				{
					id: 4,
					name: '已取消',
					img: require('../../../assets/cancel-clock.svg'),
				},
			],
		}
	},
	created() {
		this.getOrderByStatus()
	},
	watch: {
		currentTab() {
			this.getOrderByStatus()
		},
		onWsWatchOrder({ orderStatus }) {
			this.getOrderByStatus()
		},
	},
	computed: {
		onWsWatchOrder() {
			return this.$store.state.socket.onWsWatchOrder
		},
		currentTab() {
			const state = {}
			this.orderTypes.forEach((item, i) => {
				if (this.currentType - 1 === i) {
					state.name = item.name
					state.img = item.img
				}
			})
			return state
		},
		isGuest() {
			return this.$store.getters.isGuest
		},
		groupingByComboList() {
			return comboList => {
				return (
					comboList?.reduce((p, e) => {
						if (p[e.comboId]) {
							p[e.comboId].comboList.push(e)
						} else {
							p[e.comboId] = {
								comboName: e.comboName,
								comboPrice: e.comboPrice,
								comboList: [e],
							}
						}
						return p
					}, {}) ?? {}
				)
			}
		},
	},
	methods: {
		takeMealDetail(order) {
			const { hashId, orderId } = order
			if (hashId) {
				this.$router.push(`/${this.$route.params.random}/TakeMeal/${hashId}`)
			} else {
				this.$router.push(`/${this.$route.params.random}/TakeMeal/${orderId}`)
			}
		},
		async getOrderByStatus() {
			const old = this.list
			this.list = []
			const { storeId } = this.$store.state.user
			try {
				const res = await this.$api.order.getOrderByStatus({
					storeId: storeId,
					showType: this.currentType,
				})
				this.list = res.data.map(e => {
					const same = old.find(v => v.id === e.id)
					if (same) {
						return { ...e, show: same.show }
					} else {
						return { ...e, show: false }
					}
				})
			} catch (err) {}
		},
	},
}
</script>

<style lang="sass" scoped>
.container
  min-height: calc(100vh - 50px)
  position: relative

  ::v-deep .flex
    +fc
    position: absolute
    bottom: 5px
    flex-wrap: wrap
    width: calc(100% - 40px)
    transform: translateX(20px)

    > div
      width: 100%

  .list
    padding-bottom: 6px
    &.active
      border-bottom: 1px solid #D8D8D8
      margin-bottom: 12px

  .no-order
    padding-left: 17.5px

.type
  &-title
    display: flex
    align-items: center
    border-bottom: 1px solid #D8D8D8
    padding: 0 0 14.5px 17.5px
    margin-bottom: 12px

    p
      margin-left: 7px

  &-content
    margin-top: 24.5px
    padding-bottom: 42.5px

  &-img
    width: 22px

  &-detail
    display: flex
    font-size: 15px
    margin-top: 6px
    line-height: 1
    padding: 0 17.5px

    .text
      min-width: 215px
      display: flex
      align-items: center
      background-color: #F3F3F3
      border-radius: 5px
      padding: 5.25px 8px
      position: relative
      font-size: 14px
      > p:nth-child(2)
        padding: 0 5px
        color: #D8D8D8

    .btn
      width: initial
      height: initial
      border-radius: 5px
      padding: 3px 9px
      margin: 0 5px

    .right
      align-self: center
      margin-left: auto
      display: inline-flex
      align-items: center
      width: fit-content
      .total
        @media screen and (max-width: 424px)
          display: none
      .expend-icon
        height: 18px
        width: 18px
        margin-left: 5px
        align-self: center
        @media screen and (max-width: 424px)
          margin-left: 0
        img
          transition: all 0.3s
          transform: rotate(-90deg)
          width: 100%
          height: 100%
          &.active
            transform: rotate(90deg)

.order
  &-li-entertain
    font-size: 14px
    color: red
    margin-top: 3px
    span
      margin-right: 2px
  &-entertain
    font-size: 16px
    padding: 12px 17.5px 9px
    border-top: 1px dashed #D8D8D8
    color: red
    span
      margin-right: 5px
  &-count
    position: relative
    display: flex
    justify-content: space-between
    border-top: 1px solid #D8D8D8
    font-weight: bold
    font-size: 18px

    span
      padding: 17px 17.5px 9px

    span:nth-last-child(1)
      color: #432D2C

  &-grid
    transition: all 0.5s
    font-size: 16px
    padding: 4.5px 0

    &:not(:last-child)
      border-bottom: 1px solid #D8D8D8

  &-items
    display: flex
    flex-wrap: wrap
    padding: 13px 0

    &:not(:first-child)
      border-top: 1px solid #D8D8D8

  &-combo
    flex-basis: 100%
    color: #8E8E8E
    font-size: 14px
    line-height: 20px
    margin-top: 10.5px
    &__title
      margin-bottom: 3px
    &__li
      margin-left: 10px
    &__item
      padding-left: 12px
      &__options
        padding-left: 12px

  &-name
    width: 59%
    padding-right: 15px
    font-weight: bold

  &-price
    color: #432d2c

  &-amount
    flex: 1
    color: #432d2c
    font-weight: bold

.no-any
  font-size: 16px
  padding: 19.5px 0
  position: relative
  font-weight: 900

  &::after
    content: ''
    position: absolute
    left: -17.5px
    bottom: 0
    border-bottom: 1px solid #D8D8D8
    width: calc(100% + 35px)
</style>
